export default function useSortedShows(
  event: PortalEvent,
  showsMap: Map<string, PortalShow> | PortalShow[],
) {
  let shows = [] as PortalShow[]

  if (Array.isArray(showsMap)) {
    shows = showsMap
  }
  else {
    shows = Array.from(showsMap.values())
  }

  shows = shows.filter(({ event_id }) => event_id === event.id)

  return shows.sort((a, b) => {
    const aIndex = event.metadata.show_order?.indexOf(a.id)
    const bIndex = event.metadata.show_order?.indexOf(b.id)

    if (typeof aIndex === 'number' && typeof bIndex === 'number') {
      return aIndex - bIndex
    }
    else if (typeof aIndex === 'number') {
      return -1
    }
    else if (typeof bIndex === 'number') {
      return 1
    }

    return 0
  },
  )
}
