export default function useGetEvent(eventId: Ref<string>, isReturning: Ref<boolean>) {
  const router = useRouter()
  const supabase = useSupabaseClient<Supabase>()
  const data = useData()

  return computedAsync<PortalEvent | undefined>(async () => {
    let existing = data.events.get(eventId.value)

    if (existing) {
      return existing
    }

    const { data: retrieved } = await supabase
      .from('events')
      .select()
      .eq('id', eventId.value)
      .maybeSingle<PortalEvent>()

    if (retrieved) {
      data.events.set(retrieved.id, retrieved)

      existing = retrieved

      return existing
    }

    if (!existing && !isReturning.value) {
      isReturning.value = true
      router.back()
    }
  })
}
