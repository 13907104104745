import { capitalCase, snakeCase } from 'change-case'

/**
 * Capitalizes a string found in metadata.
 *
 * @param str - String to capitalize.
 * @returns Capitalized string.
 */
export default function useChangeCase(
  str: string,
  type: 'capitalCase' | 'snakeCase' = 'capitalCase',
) {
  if (type === 'capitalCase') {
    return capitalCase(str)
  }
  else if (type === 'snakeCase') {
    return snakeCase(str)
  }

  return str
}
