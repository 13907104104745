<template>
  <div
    :class="{
      'flex': true,
      'justify-between': true,
      'gap-4': true,
      'rounded border border-gray-300 p-2 md:p-6 dark:border-gray-700': bordered,
      'px-4': !(bordered || named),
      'md:pr-4 md:pt-4': isEditable && !named,
      'py-4 pr-4': named,
      'min-w-0': true,
    }"
  >
    <div class="grid min-w-0 gap-2 overflow-hidden overflow-x-scroll">
      <h5
        v-if="named ||bordered"
        class="leading-none"
      >
        {{ show.name }}
      </h5>
      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="typeof show.description === 'string' && show.description.length"
        class="mb-2"
        v-html="useLinkify(show.description)"
      />
      <!-- eslint-enable vue/no-v-html -->
      <p
        v-else-if="show.start_at || thisIndex === 0"
        class="whitespace-nowrap text-sm"
      >
        Starting at {{ showStartAt }}.
      </p>
      <p
        v-else-if="showBefore"
        class="whitespace-nowrap text-sm"
      >
        Starting after {{ showBefore.name }}
      </p>
      <p
        v-if="
          typeof (event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType]
            === 'number'
            && (event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType] !== 0
            && !useIsInt4Max(
              (event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType],
            )
        "
        class="text-sm"
      >
        {{ (useChangeCase(showType, 'capitalCase') as string).replace(/s$/, '') }} entries
        for this event are limited to
        {{ (event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType] }} per
        exhibitor.
      </p>
      <p
        v-if="
          typeof show.max_entries_per_exhibitor === 'number'
            && !useIsInt4Max(show.max_entries_per_exhibitor)
        "
        class="text-sm"
      >
        Entries for this show are limited to
        {{ show.max_entries_per_exhibitor }}
        per exhibitor.
      </p>
      <app-detail
        label="Entry fee"
        :value="thisPrice"
      >
        {{ thisPrice }}<span class="text-gray-500 dark:text-gray-300">
          {{ nextPrice }}
        </span>
      </app-detail>
      <p
        v-if="!isShowUnderEntryLimit"
        :class="{
          'text-xs': true,
          'sm:text-sm': true,
          'text-tsppink-500': true,
          'dark:text-tsppink-400': true,
          'dark:font-bold': true,
        }"
      >
        Entries for this show are no longer available.
      </p>
    </div>
    <u-dropdown
      v-if="isEditable"
      class="h-fit"
      :items="items"
      :popper="{ placement: 'bottom-end' }"
    >
      <u-button
        color="white"
        size="sm"
        label="Options"
        trailing-icon="i-ph-caret-down"
      />
    </u-dropdown>
    <u-button
      v-else-if="showEnter && isShowUnderEntryLimit"
      class="h-fit self-end"
      @click="emit('enter')"
    >
      Add entry
    </u-button>
  </div>
</template>

<script lang="ts" setup>
import type { Schema, SchemaUiContentful } from '@tarcltd/form-vue'

const props = withDefaults(
  defineProps<{
    event: PortalEvent
    show: PortalShow
    bordered?: boolean
    named?: boolean
    isEditable?: boolean
    showEnter?: boolean
    eventEntryCount?: {
      specieTotals: Record<string, number>
      showTotals: Record<string, number>
    } | null
  }>(),
  {
    bordered: false,
    named: false,
    isEditable: false,
    showEnter: false,
    eventEntryCount: () => ({ specieTotals: {}, showTotals: {} }),
  },
)
const emit = defineEmits(['enter', 'duplicate', 'delete'])
const app = useApp()
const data = useData()
const sortedShows = computed(() => useSortedShows(props.event, data.shows))
const thisIndex = computed(() =>
  sortedShows.value.findIndex((s) => s.id === props.show.id),
)
const showStartAt = computed(() => {
  if (props.show.start_at) {
    return useTime(props.show.start_at).format('h[:]mm A on dddd, MMMM Do')
  }

  if (thisIndex.value === 0) {
    return useTime(props.event.start_at).format('h[:]mm A on dddd, MMMM Do')
  }

  return ''
})
const showBefore = computed(() => {
  if (thisIndex.value === -1) {
    return undefined
  }

  return thisIndex.value === 0 ? undefined : sortedShows.value[thisIndex.value - 1]
})
const entryFeeDisplay = computed(() => {
  if (!props.show.entry_fees || props.show.entry_fees.length === 0) {
    return ['No entry fees available']
  }

  let display = [useMoneyFormat(props.show.entry_fees[0][1])]

  for (const [date, fee] of props.show.entry_fees.slice(1)) {
    if (display.length >= 2) {
      break
    }

    if (app.data.time.isBefore(date)) {
      const formattedDate = useTime(date).format('MMMM Do')

      display.push(`${useMoneyFormat(fee)} starting ${formattedDate}`)
    }
    else {
      display = [useMoneyFormat(fee)]
    }
  }

  return display
})
const thisPrice = computed(() =>
  entryFeeDisplay.value.length ? entryFeeDisplay.value[0] : 'No entry fees available',
)
const nextPrice = computed(() =>
  entryFeeDisplay.value.length > 1 ? `, ${entryFeeDisplay.value[1]}` : '',
)
const showType = computed(
  () =>
    useChangeCase(
      ((props.show.manifest as Schema).properties?._title as SchemaUiContentful)?.content
      ?? 'unknown',
      'snakeCase',
    ) as string,
)
const lowestLimit = computed(() => {
  let min = Infinity

  if (
    props.event.metadata.max_entries_per_specie
    && typeof props.event.metadata.max_entries_per_specie[showType.value] === 'number'
    && props.event.metadata.max_entries_per_specie[showType.value] < min
  ) {
    min = props.event.metadata.max_entries_per_specie[showType.value]
  }

  if (!useIsInt4Max(props.show.max_entries) && props.show.max_entries < min) {
    min = props.show.max_entries
  }

  if (
    (props.event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType.value]
    === 0
  ) {
    min = 0
  }

  return Math.max(0, min)
})
const isShowUnderEntryLimit = computed(() => {
  if (!props.eventEntryCount || typeof props.eventEntryCount !== 'object') {
    return true
  }

  if (
    (props.event.metadata.max_entries_per_specie ?? {})[showType.value] === 0
    || (props.event.metadata.max_entries_per_specie_per_exhibitor ?? {})[showType.value]
    === 0
  ) {
    // Entries are disabled for this specie
    return false
  }

  return (props.eventEntryCount.showTotals[props.show.id] ?? 0) <= lowestLimit.value
})
const items = [
  [
    {
      label: 'Edit',
      icon: 'i-ph-note-pencil',
      to: `/event/${useShortId(props.event.id)}/show/${useShortId(props.show.id)}/edit`,
    },
    {
      label: 'Duplicate',
      icon: 'i-ph-copy',
      click: () => emit('duplicate', props.show),
    },
    ...((props.eventEntryCount?.showTotals[props.show.id] ?? 0) === 0
      ? [
          {
            label: 'Delete',
            icon: 'i-ph-trash',
            click: () => emit('delete', props.show),
          },
        ]
      : []),
  ],
]
</script>
