export default function useGetEventScope(
  eventId: Ref<string>,
  isReturning: Ref<boolean>,
  mustBeEditor = false,
) {
  const router = useRouter()
  const supabase = useSupabaseClient<Supabase>()
  const auth = useAuth()
  const data = useData()

  return computedAsync<PortalScope | null | undefined>(async () => {
    let existing = data.scopes.get(eventId.value)

    if (!existing) {
      const { data: retrieved } = await supabase
        .from('scopes')
        .select()
        .eq('resource_id', eventId.value)
        .eq('scoped_user_id', auth.userId)
        .maybeSingle<PortalScope>()

      if (retrieved) {
        data.scopes.set(retrieved.resource_id, retrieved)

        existing = retrieved
      }
    }

    if (
      mustBeEditor
      && (!existing || (existing.scope !== 'editor' && existing.scope !== 'owner'))
      && !isReturning.value
    ) {
      isReturning.value = true
      router.back()
    }

    return existing
  }, null)
}
