<template>
  <u-modal v-model="internalValue">
    <u-form
      :schema="schema"
      :state="form"
      class="space-y-4 p-4"
      autocomplete="off"
      @submit="emit('delete', show)"
    >
      <div>
        <h5>Delete Show</h5>
        <p>
          Deleting an show cannot be undone. Please confirm that you wish to delete this
          show by typing <strong>{{ show?.name }}</strong>.
        </p>
      </div>
      <u-form-group
        label="Show name"
        name="show-name-confirmation"
      >
        <u-input v-model.trim="form.showNameConfirmation" />
      </u-form-group>
      <div class="flex gap-4 pt-8">
        <u-button
          color="white"
          @click="internalValue = false"
        >
          Close
        </u-button>
        <u-button
          type="submit"
          color="red"
          :disabled="loading || !isFormValid"
          :loading="loading"
        >
          Delete show
        </u-button>
      </div>
    </u-form>
  </u-modal>
</template>

<script lang="ts" setup>
import { object, string } from 'yup'

const props = defineProps<{
  modelValue: boolean
  show?: PortalShow
  loading: boolean
}>()
const emit = defineEmits(['update:modelValue', 'delete'])
const internalValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
const schema = object({
  showNameConfirmation: string()
    .required()
    .test({
      name: 'showNameConfirmation',
      message: 'Show name does not match.',
      test: (value) => value === props.show?.name,
    }),
})
const form = reactive({
  showNameConfirmation: '',
})
const isFormValid = computed(() => schema.isValidSync(form))
</script>
