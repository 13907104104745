import type { SchemaUiContentful } from '@tarcltd/form-vue'

export default async function useShowEntries(
  event?: PortalEvent,
): Promise<PortalShowTotals> {
  if (!event) {
    return {
      specieTotals: {},
      showTotals: {},
      showmanshipTotals: {},
      entryFeeTotals: {},
      extraFeeTotals: {},
      extraTotals: {},
    }
  }

  const supabase = useSupabaseClient<Supabase>()
  const data = useData()
  const existingData = data.events_entry_count.get(event.id)

  if (existingData) {
    return existingData
  }

  const { data: showObjects, error: showObjectsError } = await supabase
    .from('show_objects')
    .select('show_id ( * )')
    .eq('event_id', event.id)

  if (showObjectsError) {
    console.error(showObjectsError)
  }

  const specieTotals: Record<string, number> = {}
  const showTotals: Record<string, number> = {}
  const showmanshipTotals: Record<string, number> = {}
  for (const showObject of showObjects ?? []) {
    const showId = (showObject.show_id as unknown as PortalShow).id
    const showType = ((showObject.show_id as unknown as PortalShow)
      .manifest
      .properties
      ._title as SchemaUiContentful)
      .content as string

    if ((showObject.show_id as unknown as PortalShow).division === 'Showmanship') {
      if (!showmanshipTotals[showId]) {
        showmanshipTotals[showId] = 1
      }
      else {
        showmanshipTotals[showId]++
      }
    }
    else {
      if (!specieTotals[showType]) {
        specieTotals[showType] = 1
      }
      else {
        specieTotals[showType]++
      }

      if (!showTotals[showId]) {
        showTotals[showId] = 1
      }
      else {
        showTotals[showId]++
      }
    }
  }

  const { data: paymentsShowObjects, error: paymentsShowObjectsError } = await supabase
    .from('payments_show_objects')
    .select('*, show_object_id ( *,show_id ( * ))')
    .eq('event_id', event.id)

  if (paymentsShowObjectsError) {
    console.error(paymentsShowObjectsError)
  }

  const entryFeeTotals: Record<string, number> = {}
  for (const paymentObject of paymentsShowObjects ?? []) {
    const showObject
      = paymentObject.show_object_id as unknown as PortalShowObject
    const showId = (showObject.show_id as unknown as PortalShow).id
    const fee = paymentObject.metadata as unknown as PortalPaymentShowObject['metadata']
    if (!entryFeeTotals[showId]) {
      entryFeeTotals[showId] = fee.basis
    }
    else {
      entryFeeTotals[showId] += fee.basis
    }
  }

  const { data: paymentsExtras, error: paymentsExtrasError } = await supabase
    .from('payments_extras')
    .select()
    .eq('event_id', event.id)

  if (paymentsExtrasError) {
    console.error(paymentsExtrasError)
  }

  const extraFeeTotals: Record<string, number> = {}
  const extraTotals: Record<string, number> = {}
  for (const paymentsExtra of paymentsExtras ?? []) {
    const extra = paymentsExtra as PortalPaymentExtra
    if (!extraFeeTotals[extra.extra_id]) {
      extraFeeTotals[extra.extra_id] = extra.metadata.basis * extra.quantity
    }
    else {
      extraFeeTotals[extra.extra_id] += extra.metadata.basis * extra.quantity
    }

    if (!extraTotals[extra.extra_id]) {
      extraTotals[extra.extra_id] = extra.quantity
    }
    else {
      extraTotals[extra.extra_id] += extra.quantity
    }
  }
  const newEntriesTotal = {
    specieTotals,
    showTotals,
    showmanshipTotals,
    entryFeeTotals,
    extraFeeTotals,
    extraTotals,
  }

  data.events_entry_count.set(event.id, newEntriesTotal)

  return newEntriesTotal
}
