<template>
  <u-dashboard-layout>
    <u-dashboard-panel
      :width="250"
      :resizable="false"
      collapsible
    >
      <client-only>
        <u-dashboard-navbar
          v-if="windowWidth < 1024"
          class="!border-transparent"
          :ui="{ right: 'flex-1' }"
        >
          <template #title>
            <span />
          </template>
          <template #right>
            <div class="grid w-full justify-center">
              <nuxt-picture
                format="webp"
                src="/logo.png"
                width="112"
                class="translate-x-[-24px]"
                :img-attrs="{
                  alt: 'The Show Portal',
                  draggable: false,
                }"
              />
            </div>
          </template>
        </u-dashboard-navbar>
        <u-dashboard-sidebar>
          <template #header>
            <nuxt-picture
              v-if="windowHeight > 500 && windowWidth >= 1024"
              format="webp"
              src="/logo.png"
              width="146"
              :class="{
                'mx-auto': true,
                'mb-4': true,
                'mt-4': true,
                'hidden': true,
                'md:block': true,
              }"
              :img-attrs="{
                alt: 'The Show Portal',
                draggable: false,
              }"
            />
          </template>
          <u-dashboard-sidebar-links
            class="mt-1"
            :links="links"
          />
          <div class="flex-1" />
          <u-divider
            v-if="auth.user"
            class="sticky bottom-0"
          />
          <template
            v-if="auth.user"
            #footer
          >
            <u-dropdown
              :mode="windowWidth < 1024 ? 'click' : 'hover'"
              :items="userDropDownItems"
              :ui="{
                width: 'w-full',
                item: { disabled: 'cursor-text select-text' },
              }"
              :popper="{ strategy: 'absolute', placement: 'top' }"
              class="w-full"
            >
              <template #default="{ open }">
                <u-button
                  color="gray"
                  variant="ghost"
                  class="w-full"
                  :label="auth.user.name_first"
                  :class="[open && 'bg-gray-50 dark:bg-gray-800']"
                >
                  <template #trailing>
                    <u-icon
                      name="i-ph-dots-three-vertical"
                      class="ml-auto size-5"
                    />
                  </template>
                </u-button>
              </template>
              <template #account>
                <div class="text-left">
                  <p>Signed in as</p>
                  <p class="truncate font-medium text-gray-900 dark:text-white">
                    {{ auth.user.email }}
                  </p>
                </div>
              </template>
            </u-dropdown>
          </template>
        </u-dashboard-sidebar>
      </client-only>
    </u-dashboard-panel>
    <u-dashboard-page>
      <u-dashboard-panel grow>
        <div
          v-if="auth.user && !auth.isSelf"
          :class="{
            'w-full': true,
            'px-4': true,
            'py-1': true,
            'flex': true,
            'gap-2': true,
            'justify-between': true,
            'bg-yellow-400 dark:bg-yellow-300': true,
            'text-black': true,
            'font-bold': true,
            'text-sm': true,
          }"
        >
          <span class="w-full truncate">Impersonation mode.</span>
          <u-button
            variant="link"
            class="p-0 text-sm !text-black"
            square
            icon="i-ph-user-switch"
            @click="auth.impersonate(auth.realUserId)"
          />
        </div>
        <client-only>
          <u-dashboard-navbar>
            <template #title>
              <span
                :title="app.data.title"
                class="truncate"
              >
                {{ app.data.title }}
              </span>
            </template>
            <template #right>
              <u-chip
                v-if="auth.userCart.length > 0"
                :text="auth.userCart.length"
                size="2xl"
                color="tsppink"
                @click="app.layout.isSlideoverCartOpen = true"
              >
                <u-button
                  icon="i-ph-shopping-cart"
                  color="gray"
                />
              </u-chip>
            </template>
          </u-dashboard-navbar>
        </client-only>
        <slot />
      </u-dashboard-panel>
    </u-dashboard-page>
    <u-slideover v-model="app.layout.isSlideoverCartOpen">
      <app-cart />
    </u-slideover>
    <client-only>
      <lazy-u-dashboard-search
        v-if="auth.user"
        :groups="search"
      />
    </client-only>
  </u-dashboard-layout>
</template>

<script setup lang="ts">
// TODO: search events
const route = useRoute()
const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const auth = useAuth()
const data = useData()
const { width: windowWidth, height: windowHeight } = useWindowSize()
const useHasEventScope = computedAsync(async () => {
  if (!auth.userId.length) {
    return false
  }

  if (
    Array.from(data.scopes.values()).some(
      (scope) => scope.scope === 'owner' || scope.scope === 'editor',
    )
  ) {
    const { count } = await supabase
      .from('events')
      .select('*', { count: 'exact', head: true })
      .in('id', Array.from(data.scopes.keys()))

    return !count || count > 0
  }

  return false
}, false)
const links = computed(() => [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: 'i-ph-gauge',
    to: '/',
  },
  ...(auth.user?.metadata.is_organizer === true || useHasEventScope.value
    ? [
        {
          id: 'events',
          label: 'Events',
          icon: 'i-ph-ticket',
          defaultOpen:
            route.path === '/my/events' || route.path === '/event/create',
          children: [
            {
              label: 'My Events',
              to: '/my/events',
            },
            {
              label: 'Create Event',
              to: '/event/create',
            },
          ],
        },
      ]
    : []),
  ...(auth.user
    ? [
        {
          id: 'account',
          label: 'Account',
          icon: 'i-ph-user',
          defaultOpen: route.path.startsWith('/account'),
          children: [
            {
              label: 'Details',
              to: '/account/details',
            },
            {
              label: 'My Exhibitors',
              to: '/my/exhibitors',
            },
            {
              label: 'Payment History',
              to: '/my/payment/history',
            },
          ],
        },
        ...(auth.userCart.length > 0
          ? [
              {
                id: 'cart',
                label: 'Cart',
                icon: 'i-ph-shopping-cart',
                badge: auth.userCart.length,
                click: () => {
                  app.layout.isSlideoverCartOpen = true
                },
              },
            ]
          : []),
      ]
    : [
        {
          id: 'sign-up',
          label: 'Sign up',
          icon: 'i-ph-sparkle',
          to: '/sign-up',
        },
        {
          id: 'sign-in',
          label: 'Sign in',
          icon: 'i-ph-sign-in',
          to: '/sign-in',
        },
      ]),
])
const userDropDownItems = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    ...(auth.isAdmin
      ? [{ label: 'Admin', icon: 'i-ph-user-gear', to: '/admin' }]
      : []),
    {
      label: 'Stripe Connect',
      icon: 'i-ph-stripe-logo',
      to: '/account/stripe',
    },
  ],
  [
    {
      label: 'Terms of Service',
      icon: 'i-ph-note',
      to: '/terms',
    },
    {
      label: 'Privacy Policy',
      icon: 'i-ph-shield-warning',
      to: '/privacy-policy',
    },
    {
      label: 'Contact Us',
      icon: 'i-ph-chats-circle',
      click: () => {
        app.layout.isModalContactUsOpen = true
      },
    },
  ],
  [
    {
      label: 'Release Log',
      icon: 'i-ph-rocket-launch',
      to: '/release-log',
    },
  ],
  [
    {
      label: app.state.isDark ? 'Dark' : 'Light',
      icon: app.state.isDark ? 'i-ph-moon' : 'i-ph-sun',
      click() {
        app.state.isDark = !app.state.isDark
      },
    },
  ],
  [
    ...(auth.isAdmin === false || auth.isSelf
      ? []
      : [
          {
            label: 'Stop impersonating',
            icon: 'i-ph-user-switch',
            click: () => auth.impersonate(auth.realUserId),
          },
        ]),
    {
      label: 'Sign out',
      icon: 'i-ph-sign-out',
      click: () => {
        supabase.auth.signOut()
      },
    },
  ],
])
const search = computed(() => [
  {
    key: 'links',
    label: 'Go to',
    commands: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'i-ph-gauge',
        to: '/',
      },
      {
        id: 'my-exhibitors',
        label: 'My Exhibitors',
        icon: 'i-ph-user',
        to: '/my/exhibitors',
      },
      {
        id: 'my-payment-history',
        label: 'Payment History',
        icon: 'i-ph-user',
        to: '/my/payment/history',
      },
      {
        id: 'account-details',
        label: 'Account Details',
        icon: 'i-ph-user',
        to: '/account/details',
      },
      ...(auth.isAdmin
        ? [{ label: 'Admin', icon: 'i-ph-user-gear', to: '/admin' }]
        : []),
      ...(auth.user?.metadata.is_organizer
        ? [
            {
              id: 'my-events',
              label: 'My Events',
              icon: 'i-ph-ticket',
              to: '/my/events',
            },
            {
              id: 'event-create',
              label: 'Create Event',
              icon: 'i-ph-ticket',
              to: '/event/create',
            },
            {
              label: 'Stripe Connect',
              icon: 'i-ph-stripe-logo',
              to: '/account/stripe',
            },
          ]
        : []),

      {
        label: 'Contact Us',
        icon: 'i-ph-chats-circle',
        click: () => {
          app.layout.isModalContactUsOpen = true
        },
      },
      {
        label: 'Release Log',
        icon: 'i-ph-rocket-launch',
        to: '/release-log',
      },
      {
        label: 'Terms of Service',
        icon: 'i-ph-note',
        to: '/terms',
      },
      {
        label: 'Privacy Policy',
        icon: 'i-ph-shield-warning',
        to: '/privacy-policy',
      },
    ],
  },
  {
    key: 'actions',
    label: 'Do',
    commands: [
      ...(auth.userCart.length > 0
        ? [
            {
              id: 'cart',
              label: 'Open Cart',
              icon: 'i-ph-shopping-cart',
              badge: auth.userCart.length,
              click: () => {
                app.layout.isSlideoverCartOpen = true
              },
            },
          ]
        : []),
      ...(auth.isAdmin === false || auth.isSelf
        ? []
        : [
            {
              label: 'Stop impersonating',
              icon: 'i-ph-user-switch',
              click: () => auth.impersonate(auth.realUserId),
            },
          ]),
      {
        label: 'Sign out',
        icon: 'i-ph-sign-out',
        click: () => {
          supabase.auth.signOut()
        },
      },
    ],
  },
])

whenever(
  () => auth.userId,
  async () => {
    if (data.scopes.size === 0) {
      const { data: scopes } = await supabase
        .from('scopes')
        .select()
        .eq('scoped_user_id', auth.userId)

      data.cacheMany('scopes', scopes)
    }
  },
  { immediate: true },
)
</script>
