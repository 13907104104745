<!-- eslint-disable max-len -->
<template>
  <div
    :class="{
      'fixed': true,
      'grid': true,
      'justify-center': true,
      'top-0': true,
      'right-0': true,
      'bottom-0': true,
      'left-0': true,
      'z-50': true,
      'bg-white': true,
      'dark:bg-gray-900': true,
      'overflow-hidden': true,
      'overflow-y-scroll': true,
    }"
  >
    <u-button
      variant="ghost"
      color="white"
      class="absolute left-0 top-0 m-4"
      icon="i-ph-arrow-left"
      @click="emit('close')"
    >
      Back
    </u-button>
    <div class="flex w-full max-w-[1440px] items-center">
      <div class="box-border flex w-full flex-col gap-y-8 px-4 py-16 lg:gap-y-16">
        <h1>The choice is yours!</h1>
        <u-pricing-grid
          :compact="false"
          :ui="
            {
              strategy: 'override',
              wrapper: 'flex flex-col-reverse lg:flex-row w-full h-fit gap-8',
            } as any
          "
        >
          <u-pricing-card
            class="lg:my-10"
            title="Free Upfront"
            description="No upfront payment necessary! Your exhibitor entry fees initially pay your bill. Entry fees collected after your balance is paid will transfer to your account automatically."
            price="$249.99"
            :button="{
              label: 'Pay later',
              loading: loading,
              disabled: loading,
              click: () => emit('planSelected', false),
            }"
            :features="[
              'Unlimted Species',
              'Unlimted Divisions',
              'Unlimted Entries',
              'Unlimted Extras (Apparel, Fees, etc)',
              'Unlimted Show Admins',
              'Unlimted Customization',
              'Secure Payments',
              'Premium Support',
            ]"
          />
          <u-pricing-card
            title="Standard"
            description="Pay now and your entry fees transfer directly to your account."
            price="$249.99"
            highlight
            :badge="{
              label: 'Most popular',
              color: app.state.isDark ? 'green' : 'primary',
            }"
            :button="{
              label: 'Buy now',
              loading: loading,
              disabled: loading,
              click: () => emit('planSelected', true),
            }"
            :features="[
              'Unlimted Species',
              'Unlimted Divisions',
              'Unlimted Entries',
              'Unlimted Extras (Apparel, Fees, etc)',
              'Unlimted Show Admins',
              'Unlimted Customization',
              'Secure Payments',
              'Premium Support',
              'Collect Entry Fees Immediately',
            ]"
          />
        </u-pricing-grid>
      </div>
    </div>
  </div>
</template>
<!-- eslint-enable max-len -->

<script lang="ts" setup>
withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  {
    loading: false,
  },
)
const emit = defineEmits(['planSelected', 'close'])
const app = useApp()
</script>
